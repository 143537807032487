import Vue from "vue";
import VueRouter from "vue-router";

// vuex
import store from '../store/index'

import { Message } from 'element-ui'

const Login = () => import("../views/LoginView.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: "active",
  routes: [
    { path: "/", redirect: "/login" },
    { path: "/login", component: Login },
  ],
});

// 动态路由加载
let isToken = true;
router.beforeEach(async (to, from, next) => {
  // 登录验证
  const token = sessionStorage.getItem("token");
  let valid = true;
  if (token == null) {
    valid = false;
  }

  if (to.path !== "/login" && !valid) {
    next("/login");
    Message({
      showClose: true,
      message: '未检测到登录信息，请先登录',
      type: 'warning'
    });
  }

  // 存在路由的信息以及未加载过的时候加载
  if (isToken && store.state.routers.length != 0) {
    //从vuex中获取动态路由
    const routersInfo = await store.state.routers;
    await routersInfo.forEach((v) => {
      v.component = routerCom(v.component);
      v.children = routerChildren(v.children);
      router.addRoute(v);
    })
    //将isToken赋为 false ，否则会一直循环，崩溃
    isToken = false;
    setTimeout(() => {
      next({
        ...to, // next({ ...to })的目的,是保证路由添加完了再进入页面 (可以理解为重进一次)
        replace: true, // 重进一次, 不保留重复历史
      })
    }, 1000)
  } else {
    next();
  }
});

function routerCom(path) {
  //对路由的component解析
  return (resolve) => require([`@/views/${path}`], resolve);
}

function routerChildren(children) {
  //对子路由的component解析
  children.forEach(v => {
    v.component = routerCom(v.component);
    // 递归解析
    if (v.children != undefined) {
      v.children = routerChildren(v.children)
    }
  })
  return children
}

export default router;
