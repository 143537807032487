import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 持久化存储
    routers: JSON.parse(sessionStorage.getItem("routers")) || []
  },
  getters: {},
  mutations: {
    setRouters(state, routers) {
      // state.routers = routers;
      state.routers = routers;
      sessionStorage.setItem("routers", JSON.stringify(routers));
    },
  },
  actions: {},
  modules: {},
});
